import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js";

export default class extends Controller {
  static targets = ["clientList"];

  static values = {
    url: String,
  };

  connect() {
    if (this.hasClientListTarget) {
      /* eslint-disable-next-line no-new */
      new Choices(this.clientListTarget, {
        allowHTML: false,
        placeholder: true,
        shouldSort: false,
        maxItemCount: 5,
      });
    }
  }

  onChange() {
    if (this.clientListTarget.value) {
      // FIXME: ChoicesJS does not currently support passing data-attributes via markup
      window.location.href = `/work-streams/${this.clientListTarget.value}`;
    }
  }
}
