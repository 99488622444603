import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js";

export default class UserShareWithController extends Controller {
  static targets = ["usersSelectField"];

  connect() {
    if (this.hasUsersSelectFieldTarget) {
      /* eslint-disable-next-line no-new */
      new Choices(this.usersSelectFieldTarget, {
        allowHTML: true,
        removeItemButton: true,
        placeholder: true,
        placeholderValue: "Search users",
        position: "top",
      });
    }
  }
}
