/* eslint import/prefer-default-export: "off" */
import camelCase from "camelcase";

export const ListboxType = {
  DROPDOWN: "dropdown",
  FILTER: "filter",
};

export const populateListbox = (selectTarget, ul) => {
  Array.from(selectTarget.children).forEach((el) => {
    const li = document.createElement("li");
    li.setAttribute("role", "option");
    li.dataset.value = el.value;
    li.innerHTML = el.innerHTML;
    ul.append(li);
  });
};

/**
 * Creates a list box of elements that will be link to a controller matching
 * the type of the listbox. e.g. the `utils--dropdown` controller for the
 * `dropdown` type.
 * This controller should have a `onSelectChange` method to control the content
 * of the button.
 */
export const createListBoxElements = (
  type,
  selectTarget,
  labelTarget = null,
) => {
  const pascalCaseTypeName = camelCase(type, { pascalCase: true });

  const div = document.createElement("div");
  div.dataset.controller = `utils--${type}`;
  div.dataset[`utils-${pascalCaseTypeName}LabelValue`] = labelTarget?.innerText;
  div.dataset.action = `utils--listbox:itemSelected->utils--${type}#onSelectChange`;
  div.setAttribute("class", type);

  const button = document.createElement("button");
  button.dataset[`utils-${pascalCaseTypeName}Target`] = "button";

  const ul = document.createElement("ul");
  ul.setAttribute("tabindex", "-1");
  ul.setAttribute("role", "listbox");
  ul.setAttribute("aria-activedescendant-value", selectTarget.value);
  ul.dataset.controller = "utils--listbox";
  ul.dataset["utils-ListboxTarget"] = "listbox";
  ul.dataset[`utils-${pascalCaseTypeName}Target`] = "listbox";
  populateListbox(selectTarget, ul);

  div.append(button);
  div.append(ul);

  // Connect the current select button to the new dropdown
  div.addEventListener("utils--listbox:itemSelected", (evt) => {
    const { value } = evt.detail.element.dataset;
    selectTarget.value = value; /* eslint-disable-line no-param-reassign */
    selectTarget.dispatchEvent(new Event("change"));
  });

  // Hides the select button and inset the Dropdown
  selectTarget.classList.add("d-none");
  selectTarget.after(div);

  return { div, ul, button };
};
