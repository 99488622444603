import * as Sentry from "@sentry/browser";

import { readJsonScriptValue } from "./utils/json_script";

const dsn = readJsonScriptValue("settings-sentry-dsn-frontend", {
  defaultValue: "",
});
const release = readJsonScriptValue("settings-source-version", {
  defaultValue: "",
});
const environment = readJsonScriptValue("settings-app-stage", {
  defaultValue: "",
});

Sentry.init({
  dsn,
  release,
  environment,
  integrations: [
    Sentry.captureConsoleIntegration({
      levels: ["warn", "error", "assert"],
    }),
  ],
});
