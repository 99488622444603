import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js";
import $ from "jquery";

export default class extends Controller {
  static targets = ["tierSelectField", "tierForm"];

  connect() {
    /* eslint-disable-next-line no-new */
    new Choices(this.tierSelectFieldTarget, {
      allowHTML: true,
      removeItemButton: true,
      placeholder: true,
    });
  }

  onOrderingSelectChange() {
    this.tierFormTarget.submit();
  }

  onTierSelectChange() {
    this.tierFormTarget.submit();
  }

  onOwnershipRadioInputChange(e) {
    // uncheck all radio buttons
    $(e.target)
      .closest("form")
      .find("input[type=radio]")
      .prop("checked", false);
    // check the value of the radio button and submit
    $(e.target).prop("checked", true);

    this.tierFormTarget.submit();
  }
}
